/*
DARK
BG - #0d0d0d50
TEXT - #c9c9c9
*/

* {
  /* font-family: 'Inter', sans-serif; */
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  /* width: 100%; */
  /* border: 2px solid #1b82d650; */
}

td,
th {
  /* border: 1px solid #1b82d6; */
  /* text-align: left; */
  padding: 10px;
  padding-left: 15px;
  font-size: 14px;
}

/* tr:nth-child(even) { */
/* background-color: #1b82d610; */
/* } */

.backgroundBG_ {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.17),
      rgba(255, 255, 255, 0.17)
    ),
    linear-gradient(
      234.53deg,
      #f7fff9 15.96%,
      #effbff 46.77%,
      rgba(242, 255, 245, 0.6) 98.85%
    );
  height: 100%;
  min-height: 100vh;
}

.hover-trigger .hover-target {
  /* display: none; */
  opacity: 0;
}

.hover-trigger:hover .hover-target {
  display: flex;
  opacity: 1;
}
.showEver {
  display: flex;
}

.app-shadow {
  /* box-shadow: 0 10px 25px rgba(0, 0, 0, 0.030);*/
  box-shadow: 0px -4px 72px -16px
    var(--theme-ui-colors-elevatedShadowBg, rgba(0, 0, 0, 0.1));
}

/* .backgroundProfile { */
/* background: linear-gradient(89.96deg, #e4f1ff 9.75%, #d9fddf 90.87%); */
/* background: #ffffff */
/* } */

.backgroundBG {
  background-image: url("../../public/b_g.png");
  background-repeat: no-repeat;
  /* background:linear-gradient(0deg, rgba(112, 199, 226, 0.1), rgba(82, 243, 195, 0.1)), url('../../public/b_g.png'); */
  background-size: cover;
  min-height: 100vh;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@media (prefers-color-scheme: dark) {
  .backgroundBG {
    background-image: url("../../public/b_g_dark.png");
  }
}

.app-logo-mask {
  background-color: #ccc;
  -webkit-mask-image: url('../../public/logo_mask.png');
  mask-image: url('../../public/logo_mask.png');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  scale: 0.3;
  /* width: 600px;
  height: 400px; */
  background-size: 300%;
  -webkit-animation: AnimationName 3s ease infinite;
  -moz-animation: AnimationName 3s ease infinite;
  animation: AnimationName 3s ease infinite;
}


@keyframes AnimationName {
  0%,
  100% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}
