.dropdown {
  border-radius: 10px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.05);
  /* background-color: white; */
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
}

.dropdown-header {
  padding: 13px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-body {
  display: none;
  position: absolute;
  z-index: 10;
  overflow-y: scroll;
  max-height: 300px;
  width: 100%;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.dropdown-body.open {
  display: block;
  border-color: rgb(44, 106, 238);
}

.dropdown-item {
  padding: 10px;
  /* background-color: #ffffff; */
}

.dropdown-item:hover {
  cursor: pointer;
  border-color: red;
}

.dropdown-item-dot {
  opacity: 0;
  color: #444bf9;
  transition: all 0.2s ease-in-out;
}

.dropdown-item-dot.selected {
  opacity: 1;
}

.icon {
  font-size: 13px;
  color: #91a5be;
  transform: rotate(0deg);
  transition: all 0.2s ease-in-out;
}

.icon.open {
  transform: rotate(90deg);
}
