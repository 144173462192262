.buttonPrimary {
  /* background: linear-gradient(90deg, #444bf9 3.4%, #4497f9 94.44%); */
  background: linear-gradient(90deg, #0E7FB8 3.4%, #5E9EBE 94.44%);
  /* box-shadow: 0px 4px 24px #4497f950; */
  box-shadow: 0px 4px 24px #5E9EBE99;
  transition: .2s ease-in;
}

.buttonSuccess {
  background: linear-gradient(90deg, #37d385 3.4%, #3fee82 94.44%);
  box-shadow: 0px 4px 24px #3fee8250;
}

.buttonError {
  background: linear-gradient(90deg, #f84444 3.4%, #eb003b 94.44%);
  box-shadow: 0px 4px 24px #eb003b50;
}

.buttonWarn {
  background: linear-gradient(90deg, #f56918 3.4%, #e9c732 94.44%);
  box-shadow: 0px 4px 24px #f5691850;
}

.button:hover {
  /* background: linear-gradient(90deg, #444bf9 30.4%, #4497f9 80.44%); */
  opacity: 0.9;
}

.material-symbols-outlined {
  transition: 0.2s ease-in;
}

.button:hover .material-symbols-outlined {
  transform: translateX(40%);
}


.buttonPrimary:hover{
  background: linear-gradient(40deg, #37d385 3.4%, #5E9EBE 94.44%);
}